<template>
  <a-select
    :filter-option="filterOption"
    :placeholder="placeholder"
    :show-search="true"
    :value="value"
    allowClear
    style="width: 100%"
    @change="onChange"
  >
    <a-select-option v-for="item in dataItems" :key="item.id" :item="item" :value="item.id">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { clientsOption } from "@/api/option";

export default {
  props: ["value", "disabled", "placeholder"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataItems: [],
      queryLoading: false,
    };
  },
  methods: {
    filterOption(input, option) {
      const item = option.data.attrs.item;
      return (
        item.number.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
        item.name.toLowerCase().indexOf(input.toLowerCase()) !== -1
      );
    },
    onChange(value) {
      this.$emit("change", value);
    },
  },
  mounted() {
    this.queryLoading = true;
    clientsOption({ page_size: 999999 })
      .then((data) => (this.dataItems = data.results))
      .finally(() => (this.queryLoading = false));
  },
};
</script>

<style scoped></style>
